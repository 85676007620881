@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .content-grid {
    --padding-inline: 1.5rem;
    --content-max-width: 629px;
    --breakout-max-width: calc(
      var(--content-max-width) + (2 * var(--padding-inline))
    );
    --breakout-size: calc(
      (var(--breakout-max-width) - var(--content-max-width)) / 2
    );

    @apply grid w-full;

    grid-template-columns:
      [fullwidth-start] minmax(var(--padding-inline), 1fr)
      [breakout-start] minmax(0, var(--breakout-size))
      [content-start] min(
        100% - (var(--padding-inline) * 2),
        var(--content-max-width)
      )
      [content-end]
      minmax(0, var(--breakout-size)) [breakout-end]
      minmax(var(--padding-inline), 1fr) [fullwidth-end];
  }
  .content-breakout {
    @apply col-[breakout];
  }
  .content-fullwidth {
    @apply grid grid-cols-[inherit] col-[fullwidth];
  }
  .content-content {
    @apply col-[content];
  }
  .content-grid
    > *:not(
      .content-fullwidth,
      .content-breakout,
      .col-\[fullwidth\],
      .col-\[breakout\]
    ),
  .content-fullwidth
    > *:not(
      .content-fullwidth,
      .content-breakout,
      .col-\[fullwidth\],
      .col-\[breakout\]
    ) {
    @apply col-[content];
  }
}
